<template>
  <b-card-code title="Modifier Groupe">
    <b-card-text>
      <span>Veuillez modifier les coordonnées du Groupe </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="groups"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Année Universitaire"
              label-for="Année Universitaire"
            >
              <validation-provider
                #default="{ errors }"
                name="Année Universitaire"
                rules="required"
              >
                <b-form-input
                  v-model="groups.academic_year"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Année Universitaire"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Code du group"
              label-for="Code du group"
            >
              <validation-provider
                #default="{ errors }"
                name="Code du group"
                rules="required"
              >
                <b-form-input
                  v-model="groups.group_code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Code du group"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Parcour"
              label-for="Parcour"
            >
              <validation-provider
                #default="{ errors }"
                name="Parcour"
                rules="required"
              >
                <v-select
                  v-model="groups.parcour"
                  :clearable="false"
                  :options="parcours"
                  label="title"
                  placeholder="Parcour"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Niveau"
              label-for="Niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <b-form-input
                  v-model="groups.level"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Niveau"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5>Groupe Arabe</h5>
              <b-form-checkbox
                v-model="groups.arab_group"
                name="check-button"
                switch
                inline
                @click="groups.arab_group = true"
              >
                Groupe Arabe
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editGroup"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormCheckbox,

  },
  data() {
    return {
      password: '',
      groups: {},
      required,
      type_week: ['cours', 'ds', 'examen', 'seminiare', 'certification', 'revision', 'vacances', 'soutenance', 'mis a niveau', 'examen de rattrapage'],
      diplomas: [],
    }
  },
  created() {
    this.getGroups()
    this.getParcours()
  },
  methods: {
    async getParcours() {
      this.load = 'true'
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
      this.load = 'false'
    },
    async getGroups() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/groups/detail/${id}`)
      this.groups = response.data
      this.load = 'false'
    },
    async editGroup() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/groups/update/${id}/`,
                {
                  group_code: this.groups.group_code,
                  level: this.groups.level,
                  arab_group: this.groups.arab_group,
                  parcour: this.groups.parcour.id,
                },
              )
            this.$router.push('/groups')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Groupe Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
